.form-group {
    width: 100%;
    
    & label {
        display: block;
        
        margin-bottom: 3px;

        font-size: 2rem;
        font-weight: 600;
        color: $color-grey-dark;
    }
}