.services-dropdown {
    &__container {
        position: relative;
        width: 100%;
        font-family: $font-primary;
        font-size: 1.8rem;
        font-weight: 600;
    }
  
    &__select {
        width: 100%;
        appearance: none;
        border-radius: 0;
        border: 2px solid #000;
        padding: 1.2rem 1.3rem;

        background-color: #fff;

        &::after {
            content: "";
            width: 1.4rem;
            height: .8rem;
            background-color: #000;
            clip-path: polygon(100% 0%, 0 0%, 50% 100%);
            position: absolute;
            top: 50%;
            right: 1rem;
            transform: translateY(-50%);
        }
    }

    &__list {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        transform: translateY(100%);
        z-index: 30;
        border: 2px solid #000;
        list-style: none;
    }

    &__item {
        padding: 1rem 1.5rem;
        display: grid;
        grid-template-columns: 1fr min-content;
        line-height: 1;
        background-color: #fff;
        cursor: pointer;

        transition: all .2s ease-out;

        &:hover {
            background-color: #d3d3d3;
        }
        
        &:not(:last-child) {
            border-bottom: 1px solid #000;
        }
        
        &--disabled {
            cursor: not-allowed;
            background-color: #d3d3d3;
        }
    }

    &__service {
        font-size: 1.7rem;
    }

    &__time {
        font-size: 1.4rem;
        color: $color-grey-dark;
        font-weight: 400;
    }

    &__price {
        font-size: 1.5rem;
        align-self: center;
    }

    &__selected-services {
        color: #fff;
    }

    &__selected-list {
        list-style: none;
    }

    &__selected-item {
        padding: .3rem 1.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:first-child {
            margin-top: 1rem;
        }
    }

    &__selected-name {
        font-size: 1.7rem;
    }

    &__selected-delete {
        width: 11px;
        height: 11px;
        cursor: pointer;
        & img {
            width: 100%;
            height: 100%;
        }
    }
};
