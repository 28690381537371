.nav {
    display: flex;
    align-items: center;

    &__logo {
        text-decoration: none;
        margin-right: auto;
    }
    
    &__icons {
        display: grid;
        grid-template-columns: repeat(2, min-content);
        align-items: center;
        column-gap: 9rem;
        @include respond(tab-land) {
            column-gap: 5rem;
        }
        
        @include respond(tab-port) { // width < 900?
            column-gap: 9rem;
        }
        
        @include respond(phone) { // width < 600?
            column-gap: 5rem;
        }
    }

    &__shopping-bag-group {
        position: relative;
    }

    &__shopping-bag-notification {
        position: absolute;
        top: -0.8rem;
        right: -1.2rem;

        width: 2rem;
        height: 2rem;

        display: flex;
        justify-content: center;
        align-items: center;

        background-color: #000;
        color: #fff;
        padding: 0.4rem;
        border-radius: 50%;
    }
}
