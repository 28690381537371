.testimonials {
    grid-column: full-start / full-end;

    position: relative;

    padding: 10rem 9rem;
    display: flex;
    align-items: flex-end;

    @include respond(tab-land) { // width < 1200?
        padding: 10rem 6rem;
    }

    @include respond(tab-port) { // width < 900?
        padding: 10rem 6rem 5rem;
    }

    @include respond(phone) { // width < 600?
        padding: 10rem 2rem 5rem;
    }

    & h3 {
        position: absolute;
        top: 15%;
        left: 18%;
        
        @include respond(tab-port) { // width < 900?
            top: 0;
            left: 6rem;
        }

        @include respond(phone) { // width < 600?
            left: 2rem;
        }
    }

    &__main {
        display: flex;
        align-items: flex-end;
        height: 80%;
        max-width: 100%;

        @include respond(tab-port) { // width < 900?
            flex-direction: column;
            height: 100%;
        }
    }

    &__content {
        flex: 1;
        font-weight: 600;

        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: min-content minmax(min-content, 5rem) 1fr min-content;
        height: 100%;
        
        @include respond(tab-port) { // width < 900?
            flex: auto;
            align-self: start;
            justify-self: center;
            margin-top: 5rem;
            grid-template-rows: min-content minmax(min-content, 4rem) minmax(max-content, 17rem) min-content;
        }
        
        @include respond(phone) { // width < 600?
            grid-template-rows: minmax(8rem, min-content) min-content minmax(min-content, 4rem) min-content;
        }
    }

    &__name {
        margin-top: 10rem;
        font-size: 2.5rem;
        line-height: 1;
        
        @include respond(tab-port) { // width < 900?
            margin-top: 0;
        }
    }

    &__title {
        font-size: 1.7rem;
        color: $color-grey-med;   
    }
    
    &__paragraph {
        font-size: 2.7rem;

        @include respond(phone) { // width < 600?
            font-size: 2rem;
        }
    }
    
    &__btn-group {
        display: flex;

        @include respond(phone) {
            grid-row: 1/ 2;
        }
        & button {
            &:first-child {
                margin-right: 6rem;
            }
            background-color: $color-primary;
            width: 5.7rem;
            height: 5.7rem;
            border:none;
            border-radius: 50%;

            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            outline:none;
            transition: all .1s;

            &:hover {
                filter: brightness(.97);
            }
        }
    }

    &__images {
        flex: 0 0 72%;
        display: flex;
        align-items: flex-end;

        height: 100%;
        direction: rtl; // Life saver

        overflow-x: hidden;
        overflow-y: auto;
        margin-right: 5rem;

        @include respond(tab-land) { // width < 1200?
            flex: 0 0 60%;
        }

        @include respond(tab-port) { // width < 900?
            margin-right: 0;
        }
    }

    &__figure {
        &:not(:first-child) {
            margin-right: 50px;
        }

        max-width: 25rem;
        min-width: 25rem;
        height: 33rem;
        text-align: right;
        filter: grayscale(80%);
        
        &--active {
            height: 100%;
            max-width: 50rem;
            min-width: 50rem;
            filter: grayscale(0%);

            @include respond(tab-land) { // width < 1200?
                min-width: 40rem;
                max-width: 40rem;
            }

            @include respond(tab-port) { // width < 900?
                max-width: 45rem;
                min-width: 45rem;
            }

            @include respond(phone) { // width < 600?
                max-width: 90vw;
                min-width: 90vw;
            }
        }
    }

    &__image {
        object-position: center;
        object-fit: cover;
        width: 100%;
        height: 100%;
        display: block;
    }
}
