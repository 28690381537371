.header {
    grid-column: col-start 5 / full-end;
    padding: 5rem 7rem;
    
    @include respond(tab-port) { // width < 900?
        grid-column: full-start / full-end;
        padding: 5rem 7rem 0;
    }

    @include respond(phone) { // width < 600?
        padding: 5rem 0;
        grid-column: center-start / center-end;
    }
    display: grid;
    grid-template-rows: 1fr min-content 1fr;
    align-items: start;

    &__details {
        display: grid;
        grid-template-rows: repeat(2, min-content);
        row-gap: 4rem;
        justify-items: start;
        transform: translateY(4rem); // minus the row gap
    }

    &__end {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        align-self: end;

        & img {
            transform: translateY(5.5rem);
            cursor: pointer;
            
            transition: all .15s ease-in-out;
            
            &:hover {
                transform: translateY(6.5rem);
            }

            @include respond(phone) { // width < 600?
                height: 24rem;
            }
        }
    }

    &__select-word {
        border-bottom: 5px solid $color-primary;
    }
}
