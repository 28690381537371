@keyframes rotateElem {
    0% {
        opacity: 0;
        visibility: hidden;
        display: none;
        transform: translateY(-50px);
    }
    2% {
        opacity: 1;
        visibility: visible;
        display: inline-block;
        transform: translateY(0px);
    }
    18% {
        opacity: 1;
        visibility: visible;
        display: inline-block;
        transform: translateY(0px);
    }
    20% {
        opacity: 0;
        visibility: hidden;
        display: none;
        transform: translateY(50px);
    }
    100% {
        opacity: 0;
        visibility: hidden;
        display: none;
        transform: translateY(50px);
    }
}

@keyframes fadeInOut {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.4;
    }

    100% {
        opacity: 1;
    }
}

@keyframes moveInOut {
    0% {
        top: 0;
        left: 0;
        box-shadow: none;
    }
    
    50% {
        top: 1.15rem;
        left: 1.15rem;
        box-shadow: 0 .3rem .7rem rgba(#000, .2);
    }
    
    100% {
        top: 0;
        left: 0;
        box-shadow: none;
    }
}