.barber-card {
    background-color: #fff;
    max-width: 49rem;
    min-width: 49rem;
    height: 31rem;
    padding-left: 5rem;

    display: grid;
    grid-template-columns: 1fr min-content;
    grid-template-rows: 1fr;
    align-content: center;

    position: relative;
    overflow: hidden;

    @include respond(phone) {
        // width < 600?
        max-width: 100%;
        min-width: 100%;
        padding-left: 3rem;
        min-height: 25rem;
        max-height: 25rem;
    }

    &:hover &__figure img {
        filter: drop-shadow(0 0.5rem 1.7rem rgba(#000, 0.28));
        transform: scale(1.02);

        @include respond(phone) {
            // width < 600?
            filter: drop-shadow(1rem 0.5rem 1.7rem rgba(#000, 0.28));
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        padding: 6rem 0 4rem;

        @include respond(phone) {
            // width < 600?
            padding: 4.5rem 0 2rem;
        }
    }

    &__badge {
        position: absolute;
        top: 2.3rem;
        left: 4.8rem;

        padding: 0.5rem 2rem;
        border-radius: 50px;

        background: #fad775;
        font-size: 1.4rem !important;
        color: black;

        @include respond(phone) {
            top: 1.3rem;
            left: 2.9rem;
            font-size: 1.3rem !important;
            padding: 0.4rem 1.8rem;
        }
    }

    &__name {
        font-size: 3rem;
        font-weight: 600;
        line-height: 1;
        letter-spacing: 0.5px;

        @include respond(phone) {
            // width < 335?
            font-size: 2.4rem;
        }
    }

    & span {
        font-size: 1.9rem;
        @include respond(phone) {
            // width < 600?
            font-size: 1.8rem;
        }
    }

    &__rank {
        color: $color-grey-dark;
        margin-bottom: 1rem;
    }

    &__rating {
        display: flex;
        align-items: center;
        margin-bottom: auto;
        transform: translateX(-3px);
        & img {
            margin-right: 0.5rem;
            width: 3rem;
        }
        & span {
            transform: translateY(1.5px);
        }
    }

    & button {
        margin-top: auto;
        align-self: start;
    }

    &__figure {
        height: 130%;

        position: absolute;
        right: -10%;
        bottom: -35%;

        & img {
            height: 97%;
            filter: drop-shadow(0 0.5rem 1rem rgba(#000, 0.25));
            transition: filter 0.2s ease-out, transform 0.2s ease-out;

            @include respond(phone) {
                // width < 600?
                filter: drop-shadow(1rem 0.5rem 1rem rgba(#000, 0.25));
            }
        }
    }

    // Adjust for Kushtrims picture
    &:nth-child(6) {
        .barber-card__figure {
            right: -3%;
        }
    }
}
