.pagination {
    display: flex;

    &__icon {
        cursor: pointer;
        transition: all .15s ease-out;

        &--inactive {
            opacity: .5;
        }
    }

    &__page {
        font-family: $font-display;
        font-size: 3rem;
        opacity: .5;
        transition: all .15s ease-out;

        &:hover {
            opacity: 1;
        }

        &--active {
            opacity: 1;
        }
    }

    &__link {
        text-decoration: none;
        color: #000;
    }

    & li {
        list-style: none;
        &:not(:last-child) {
            margin-right: 6rem;
        }
    }
}