.loader {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0%;
    left: 0%;

    display: grid;
    justify-content: center;
    align-content: center;

    & .logo {
        margin: 0 auto;
    }

    &  .fade-in-out {
        transform: translateY(-50%) scale(1.7) !important;
    }
}
