.gallery {
    background-color: #000;
    grid-column: full-start / col-end 4;

    @include respond(tab-port) { // width < 900?
        grid-row: 2 / 3;
        grid-column: full-start / full-end;
    }

    &__content {
        background-size: cover;
        background-position: center;
    
        display: grid;
        grid-template-rows: 1fr;
        padding: 7rem 3rem;
        position: relative;

        height: 100%;
        width: 100%;
    }

    &__button-group {
        align-self: center;

        display: flex;
        justify-content: space-between;
    }

    &__button {
        background-color: transparent;
        border: none;
        outline: none;
        opacity: 0.4;
        width: 7rem;
        cursor: pointer;

        transition: all 0.2s;

        &:hover {
            opacity: 1;
        }

        & img {
            display: block;
            width: 100%;
            height: 100%;
        }
    }

    &__counter {
        font-family: $font-display;
        font-size: 8.5rem;
        color: #fff;
        line-height: 1;
        position: absolute;
        bottom: 7rem;
        left: 3rem;

        @include respond(tab-land) { // width < 1200?
            font-size: 8rem;
        }
    }
}
