.barbers {
    grid-column: full-start / full-end;
    background-color: #000;
    
    position: relative;
    padding: 10rem 0;

    display: grid;
    grid-template-columns:
        [full-start] minmax(6rem, 1fr)
        [center-start] repeat(8, [col-start] minmax(min-content, 20rem) [col-end]) [center-end]
        minmax(6rem, 1fr) [full-end];
    grid-template-rows: min-content 1fr;
    
    @include respond(phone) { // width < 600?
        grid-template-columns:
        [full-start] 2rem
        [center-start] 1fr [center-end]
        2rem [full-end];
        
        row-gap: 4rem;
        grid-template-rows: repeat(2, min-content);
        padding-bottom: 1rem
    }

    &__heading {
        grid-column: center-start / center-end;
    }
    
    &__cards {
        grid-column: center-start / full-end;
        align-self: center;
        
        overflow: hidden;
        
        display: flex;
        align-content: center;
        align-items: center;

        @include respond(phone) { // width < 600?
            grid-column: center-start / center-end;
            flex-direction: column;

            height: 75rem;
            padding: 0 1rem;
        }

        & > * {
            &:not(:last-child) {
                margin-right: 120px;

                @include respond(tab-port) { // width < 900?
                    margin-right: 80px;
                }

                @include respond(phone) { // width < 600?
                    margin-right: 0;
                    margin-bottom: 4rem;
                }
            }
        }

        &--no-overlay {
            height: auto;
            padding-bottom: 10rem;
        }
    }

    &__cards-overlay {
        display: none;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 23rem;
        padding-top: 5rem;
        background-image: linear-gradient(
            to bottom, 
            rgba(#000, .01) 0%, 
            rgba(#000, .1) 10%, 
            rgba(#000, .7) 30%, 
            rgba(#000, .85) 50%, 
            rgba(#000, .9) 60%, 
            rgba(#000, .95) 80%,
            rgba(#000, 1) 100%
        );
        
        @include respond(phone) { // width < 600?
            display: flex;
            justify-content: center;
            align-items: center;
        }

    }
}