// IMPORT FONTS
@font-face {
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('../static/fonts/SpaceGrotesk-Light.ttf');
}
@font-face {
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('../static/fonts/SpaceGrotesk-Regular.ttf');
}
@font-face {
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('../static/fonts/SpaceGrotesk-SemiBold.ttf') format('ttf');
}
@font-face {
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('../static/fonts/SpaceGrotesk-Bold.ttf') format('ttf');
}

@import url('https://fonts.googleapis.com/css2?family=Prata&family=Lato:wght@300&family=Poppins:wght@900&display=swap');
