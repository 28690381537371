.logo {
    width: 17rem;
    
    & img {
        width: 100%;
    }
    
    @include respond(phone) { // width < 600?
        width: 16rem;    
    }
}