.product-card {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 3rem;
    padding: 3rem 3.5rem;
    width: 100%;
    max-width: 38rem;
    transition: all .14s ease-in;
    
    position: relative;

    &:hover {
        transform: scale(1.007) translateY(-4px);
    }

    &:hover &__background {
        box-shadow: -.8rem 1.4rem 3.3rem rgba(#000, .14);
    }

    & > * {
        z-index: 1;
    }
    
    &__background {
        background-color: #fff;
        box-shadow: -.9rem 1.6rem 3.1rem rgba(#000, .16);
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 70%;
        z-index: 0;
        transition: all .3s ease-in-out;
    }
    
    &__figure {
        justify-self: center;
        height: 40rem;
        width: auto;
        cursor: pointer;

        & img {
            width: auto;
            height: 100%;
        }
    }

    &__content {
        overflow: hidden;
    }

    &__title {
        font-size: 2.5rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor: pointer;
    }
    
    &__price {
        font-size: 1.8rem;
        color: $color-grey-dark;
    }

    &__sold-out {
        font-size: 1.8rem;
        color: #C70000;
    }
}