.certification {
    max-width: 30rem;
    width: 100%;
    position: relative;
    
    margin: 0 2rem;
    
    @include respond(tab-land) {
        max-width: 33rem;
    }
    
    @include respond(phone) { // width < 600?
        max-width: 17.5rem;       
        margin: 0 2rem 0 1rem;
    }

    &::after {
        content: '';
        transform: translateY(0.5rem);
        width: 80%;
        background-color: $color-primary;
        height: 4px;
        position: absolute;
        left: 10%;
        bottom: -4rem;
        
        @include respond(phone) { // width < 600?
            bottom: -2rem;
        }
    }

    & > img {
        overflow: hidden;
        animation: rotateElem 10s linear infinite;
        visibility: hidden;
        opacity: 0;
        position: absolute;
        top: -2rem;

        @include respond(tab-port) { // width < 900?
            height: 5rem;
            width: auto;
        }
        
        @include respond(phone) { // width < 600?
            height: 2.5rem;
        }

        &:nth-child(1) {
            animation-delay: 0s;
            z-index: 1;
            left: 0.5rem;
        }
        &:nth-child(2) {
            animation-delay: 3.33s;
            z-index: 2;
            left: 1.5rem;
        }
        &:nth-child(3) {
            animation-delay: 6.7s;
            z-index: 3;
            left: 0.9rem;
        }
    }
}
