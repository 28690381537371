// COLOR VARIABLES
$color-primary: #ffbe05;

$color-grey-light: #fcfcfc;
$color-grey-med: #a7a7a7;
$color-grey-dark: #797777;

// FONT VARIABLES
$font-primary: 'Space Grotesk', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
$font-display: 'Prata', serif;

*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
    box-sizing: border-box;
    font-size: 62.5%; // 10px/16px = 62.5% => 1rem = 10px

    @include respond(tab-land) {
        // width < 1200?
        font-size: 56.25%; //1 rem = 9px, 9/16 = 50%
    }

    @include respond(tab-port) {
        // width < 900?
        font-size: 50%; //1 rem = 8px, 8/16 = 50%
    }
}

body {
    font-family: $font-primary;
    font-weight: 400;
    color: #000;
    line-height: 1.3;
}

// HELPER CLASSES
.mb-sm {
    margin-bottom: 1rem;
}

.mb-md {
    margin-bottom: 3rem;
}

.mb-lg {
    margin-bottom: 5rem;
}

.mt-sm {
    margin-top: 1rem;
}

.mt-md {
    margin-top: 3rem;
}

.mt-lg {
    margin-top: 5rem;
}
