.input {
    width: 100%;
    border-radius: 0;
    border: 2px solid #000;
    padding: 1.2rem 1.3rem;
    font-family: $font-primary;
    font-size: 1.8rem;
    font-weight: 600;
    outline: none;

    &[type="date"] {
        -webkit-appearance: textfield;
        -moz-appearance: textfield;
        min-height: 3.2em; 
    }

    &:disabled {
        background-color: #dedede;
    }
}