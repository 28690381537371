.install-message {
    width: 90%;
    padding: 1rem 2rem;

    position: fixed;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);

    display: flex;
    align-items: center;
    
    background-color: #dfdfdf;
    font-size: 1.6rem;
    
    z-index: 100;

    & svg {
        height: 100%;
        width: auto;
        margin-right: 1.5rem;
    }

    &__share {
        height: 2rem;
        width: auto;
        transform: translateY(3px);
    }

    &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: -2rem;
        left: 50%;
        transform: translateX(-50%);
        width: 0px;
        height: 0px;
        border: 10px solid  #dfdfdf;
        border-color:  #dfdfdf transparent transparent transparent;
    }
}
