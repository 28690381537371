.shopping-bag {
    padding: 5rem 0 0;

    grid-column: center-start / center-end;

    &__main {
        padding: 10rem 5rem;

        @include respond(tab-land) { // width < 1200?
            padding: 10rem 0;
        }

        display: grid;
        grid-gap: 10rem;
        grid-template-columns: repeat(2, 1fr);
        align-content: start;
        align-items: start;
        
        @include respond(tab-land) { // width < 1200?
            grid-gap: 5rem;
        }
        
        @include respond(tab-port) { // width < 900?
            grid-template-columns: 1fr;
        }
        
        &--empty {
            grid-template-columns: 1fr;
            height: 100%;
            align-content: center;
        }
    }

    &__box {
        background-color: #fff;
        box-shadow: 0px 0px 30px rgba(#000, 0.12);
        min-height: 73rem;
        padding: 5rem;
        @include respond(tab-land) { // width < 1200?
            padding: 4rem;
        }
        @include respond(tab-port) { // width < 900?
            padding: 6rem;
        }
        @include respond(phone) { // width < 600?
            padding: 5rem 3rem;
        }
        display: grid;
        grid-template-rows: minmax(6rem, min-content) 1fr;
        row-gap: 1rem;
    }

    &__box-heading {
        font-size: 2.5rem;
    }

    &__address {
        display: grid;
        grid-template-rows: 1fr min-content;
    }

    &__form {
        display: grid;
        grid-template-rows: repeat(3, min-content) 1fr;
        grid-row-gap: 2.3rem;
        justify-items: start;

        width: 60%;
        @include respond(tab-land) { // width < 1200?
            width: 90%;
        }
        
        @include respond(tab-port) { // width < 900?
            grid-template-rows: repeat(3, min-content) minmax(12.9rem, 1fr);
            width: 65%;
        }

        @include respond(phone) { // width < 600?
            width: 90%;
        }

        & > *:nth-child(4) {
            width: 80%;
        }
    }

    &__info {  
        margin-top: 1.5rem;
        font-size: 1.5rem;
        display: flex;
        align-items: center;
        & img {
            margin-right: .8rem;
        }
    }

    &__products-cont{
        display: grid;
        grid-template-rows: 1fr min-content;
        row-gap: 8rem;
        padding: 0 2rem 0;
        @include respond(tab-land) { // width < 1200?
            padding: 0;
        }
    }

    &__total {
        display: grid;
        grid-template-columns: 1fr min-content;
        row-gap: 1rem;
    }

    &__shipping-amount {
        font-size: 2rem;
        color: $color-grey-dark;
        font-weight: 600;
    }

    &__total-amount {
        font-size: 2.5rem;
        color: #000;
        font-weight: 600;
        & span {
            display: block;
            font-size: 1.2rem;
            color: $color-grey-dark;
            text-align: center;
        }
    }

    &__empty {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;

        transform: translateY(-100%);
        
        @include respond(tab-land) { // width < 1200?
            transform: translateY(-58%);
        }
        @include respond(phone) { // width < 600?
            transform: translateY(-40%);
        }
        
        & p {
            font-size: 7rem;
            font-weight: 600;
            margin-bottom: 4rem;
            text-align: center;
            
            @include respond(phone) { // width < 600?
                font-size: 5rem;
            }
        }
    }
}
