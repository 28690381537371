.heading {
    font-family: $font-display;
    &--primary {
        font-size: 7rem;
        
        @include respond(tab-land) { // width < 1200?
            font-size: 5.5rem;
        }

        @include respond(tab-port) { // width < 900?
            font-size: 8rem;
        }
        
        @include respond(phone) { // width < 600?
            font-size: 5rem;
        }
    }

    &--secondary {
        font-size: 5.7rem;
        @include respond(tab-port) { // width < 900?
            font-size: 4rem;
        }
        
        @include respond(phone) { // width < 600?
            font-size: 2.5rem;
        }
    }
    
    &--tertiary {
        font-size: 4.5rem;
        
        @include respond(phone) { // width < 600?
            font-size: 3rem;
        }
    }
}
