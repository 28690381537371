.barber-modal {
    width: 100%;
    height: 100%;

    display:grid;
    grid-template-columns: 40% 60%;

    @include respond(tab-port) { // width < 900?
        grid-template-columns: 1fr;
        grid-template-rows: 40% 60%;
    }
    
    @include respond(phone) { // width < 600?
        grid-template-rows: 55% 45%;
    }
    
    &__picture {
        background-color: #fff;
        display: flex;
        align-items: flex-end;
        justify-content: center;

        overflow: hidden;

        & img {
            max-height: 80rem;
            height: 100%;
            transform: translateY(3px);
            filter:  drop-shadow(0 .5rem 1.5rem rgba(#000, .3));

            transition: filter .2s ease-out,
                        transform .2s ease-out;

            &:hover {
                filter: drop-shadow(0 .5rem 1.8rem rgba(#000, .4));
            }

            @include respond(tab-land) { // width < 1200?
                height: 80%;
            }
            
            @include respond(tab-port) { // width < 900?
                height: 100%;
                max-height: 95%;
                width: auto;
            }
        }
    }


    &__heading {
        font-size: 2.5rem;
        color: #fff;
        font-weight: 600;
        margin-bottom: 2.5rem;

        &--review {
            margin-bottom: 2rem;
        }
    }
    
    &__form-group-cont {
        display: grid;
        grid-template-columns: repeat(2, 50%);
        grid-column-gap: 5rem;
        grid-row-gap: 2rem;
        
        @include respond(phone) { // width < 600?
            grid-column-gap: 2rem;
        }

        & > *:nth-child(4) {
            width: 60%;
            @include respond(phone) { // width < 600?
                width: 100%;
            }
        }
    }

    &__entries {
        overflow-y: scroll;
        height: 100%;
        position: relative;


        &::-webkit-scrollbar {
            width: 1rem;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: .4rem;
            background-color: rgba($color-primary, .4);
        }
    }

    &__entry-container {
        padding: 9rem;

        @include respond(tab-land) { // width < 1200?
            padding: 7rem;
        }
        
        @include respond(phone) { // width < 600?
            padding: 5rem 3rem;
        }
        
        &--form {
            width: 85%;
            @include respond(tab-land) { // width < 1200?
                width: 100%;
            }
        }
        
        &--review {
            width: 70%;
            @include respond(tab-land) { // width < 1200?
                width: 80%;
            }
            @include respond(phone) { // width < 600?
                width: 100%;
            }
        }
    }

    &__entry-group {
        margin-bottom: 6rem;
    }

    &__info {
        & p {
            font-size: 1.8rem;
            color: #fff;

            display: flex;
            justify-content: space-between;

            &:not(:last-child) {
                margin-bottom: 1rem;
            }
        }

        &:nth-child(2) {
            margin-bottom: 3rem;
        }
    }
    
    &__info-heading {
        font-size: 2.2rem;
        margin-bottom: 5px;
        color: #fff;
    }
    
    &__total {
        font-size: 2rem !important;
        font-weight: 600;
        margin-top: 4rem;
    }

    &__btn-group {
        display: flex;
        :first-child {
            margin-right: 3rem;
        }
    }
}