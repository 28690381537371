.about {
    grid-column: center-start / center-end;
    padding: 15rem 0;

    display: flex;
    align-items: center;

    @include respond(tab-land) {
        display: grid;            
        grid-template-columns: 1fr;
        grid-template-rows: min-content auto;
        row-gap: 5rem;
    }

    @include respond(phone) { // width < 600?
        padding: 10rem 0;
    }

    &__main {
        max-width: 65%;
        display: grid;
        grid-template-rows: min-content auto min-content;
        row-gap: 4rem;
        margin-right: 7rem;

        & h2 {
            display: flex;
            align-items: center;
        }

        @include respond(tab-land) {
            grid-row: 2 / 3;   
            margin-right: 0;
            max-width: 100%;
        }
    }

    &__description {
        font-weight: 300;
        font-size: 2.5rem;
        
        @include respond(phone) { // width < 600?
            font-size: 2rem;
        }
    }

    &__btn-group {
        margin-top: 2rem;

        @include respond(phone) { // width < 600?
            display: flex;   
            flex-direction: column;
            align-items: flex-start;
            margin-top: 0;
        }

        & a {
            &:first-child {
                margin-right: 3rem;
                @include respond(phone) { // width < 600?
                    margin-right: 0;
                    margin-bottom: 1rem;
                }
            }
        }
    }

    &__image {
        width: 100%;

        & > img {
            width: 100%;
        }

        @include respond(tab-land) {
            max-width: 30vw;   
            grid-row: 1 / 2;
        }
        
        @include respond(phone) { // width < 600?
            max-width: 70vw;   
        }
    }
}
