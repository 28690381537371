.navigable-header {
    color: #fff;

    display: flex;
    justify-content: space-between;
    align-content: center;

    &__btn-group {
        display: flex;
        align-items: center;
        align-content: center;

        @include respond(phone) {
            display: none;
        }
    }
    
    &__button {
        &:last-child {
            margin-left: 5rem;
        }
        background-color: transparent;
        width: 5.7rem;
        height: 5.7rem;
        border:none;

        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        outline: none;

        &--inactive {
            opacity: 0.5;
        }
    }
}