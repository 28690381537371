.shopping-bag-product {
    display: grid;
    grid-template-columns: minmax(9rem, min-content) 1fr;
    column-gap: 6rem;
    align-items: center;
    
    @include respond(tab-land) { // width < 1200?
        column-gap: 4rem;
    }

    &:not(:last-child) {
        margin-bottom: 5rem;
        padding-bottom: 5rem; // So that the border is half way between two elements
        border-bottom: 1px solid #AEAEAE;
    }

    &__img {
        justify-self: center;
        height: 14rem;
        width: auto;
        
        & img {
            width: auto;
            height: 100%;
        }
    }

    &__main {
        display: grid;
        grid-template-columns: 1fr min-content;
        column-gap: 6rem;

        & p {
            font-size: 2.3rem;
            font-weight: 600;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    &__price {
        font-size: 2.3rem;
        font-weight: 600;
        color: $color-grey-dark;
    }

    &__actions {
        display: flex;
        align-items: center;
        margin-top: 1rem;
        button {
            display: flex;
            align-items: center;
            background: none;
            border: none;
            cursor: pointer;
            outline: none;
            font-size: 1.6rem;
            font-weight: 600;

            & img {
                margin-right: .5rem;
            }
        }
    }

    &__amount {
        position: relative;
        margin-right: 3rem;

        &::after {
            content: "";
            width: 1rem;
            height: .5rem;
            background-color: #000;
            clip-path: polygon(100% 0%, 0 0%, 50% 100%);
            position: absolute;
            top: 50%;
            right: 1rem;
            transform: translateY(-50%);
        }

        select {
            width: 5.5rem;
            appearance: none;
            border-radius: 0;
            border: 2px solid #000;
            padding: .8rem 1rem;
            font-size: 1.4rem;
            font-weight: 600;
            outline: none;
            &::-ms-expand {
                display: none;
            }
        }
    }
}