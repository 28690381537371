.credits {
    position: relative;
    border-bottom: 3px solid #c73d3a;

    &:hover &__tooltip {
        visibility: visible;

        opacity: 1;
        transform:translateX(-50%)translateY(0);
    }

    &--light > *, &--display > * {
        color: #fff;
    }

    &--display > * {
        font-size: 2rem;
        font-weight: 300;
    }

    &--dark > * {
        color: #000;
    }

    &__tooltip {
        white-space: nowrap; 
        padding: 1rem 1.4rem;
        
        display: flex;
        justify-content: center;
        align-items: center;
        
        font-size: 1.3rem;
        font-family: $font-primary;
        background-color: #c73d3a;
        color: #e9e0bb;
        border-radius: 50px;

        position: absolute;
        top: -4.5rem;
        left: 50%;
        opacity: 0;
        visibility: hidden;
        transform: translateX(-50%) translateY(-3px);
        transition: all .2s;

        &::after {
            content: "";
            display: block;
            position: absolute;
            bottom: -1rem;
            left: 50%;
            transform: translateX(-50%);
            width: 0px;
            height: 0px;
            border: 5.5px solid #c73d3a;
            border-color: #c73d3a transparent transparent transparent;
        }
    }
}