.footer {
    grid-column: full-start / full-end;
    
    background-color: #000;
    color: #fff;
    padding: 15rem 30rem;
    
    @include respond(tab-land) { // width < 1200?
        padding: 10rem 12rem;
    }
    
    display: grid;
    grid-template-columns: minmax(min-content, 40rem) 1fr min-content;
    align-content: center;
    align-items: start;
    
    @include respond(tab-port) { // width < 900?
        align-items: center;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: min-content 1fr;
        row-gap: 3rem;
        justify-items: center;
        padding: 7rem 9rem;
    }
    
    @include respond(phone) { // width < 600?
        align-items: center;
        grid-template-columns: 1fr;
        grid-template-rows: min-content 1fr;
        row-gap: 4rem;
        justify-items: center;
        padding: 7rem 5rem;
    }
    
    & a {
        align-self: center;
        text-decoration: none;

        @include respond(tab-port) { // width < 900?
            grid-row: 1 / 2;
            grid-column: 1 / -1;
        }
    }

    &__group {
        display: grid;
        justify-items: start;
        grid-template-columns: auto;
        row-gap: 1.5rem;
        @include respond(phone) { // width < 600?
            justify-self: start;

            &:first-child {
                margin-top: 2rem;
            }
        }
    }

    &__heading {
        font-size: 3rem;
        margin-bottom: .7rem; 

        font-weight: 600;
        @include respond(phone) { // width < 600?
            font-size: 2.5rem;
        }
    }
    
    &__info {
        font-size: 2rem;
        font-weight: 300;

        @include respond(phone) { // width < 600?
            font-size: 1.7rem;
        }

        & a {
            &:link,
            &:visited {
                color: #fff;
                text-decoration: none;
            }
        }
    }
};
