.quantity-selector {
    display: grid;
    grid-template-columns: repeat(3, min-content);
    align-items: center;

    & button {
        background: none;
        outline: none;
        border: none;
        cursor: pointer;
    }

    & > * {
        color: #fff;
        font-size: 2.5rem;
        &:not(:last-child) {
            margin-right: 4rem;
        }
    }
}