.container {
    display: grid;
    grid-template-columns:
        [full-start] minmax(6rem, 1fr)
        [center-start] repeat(8, [col-start] minmax(min-content, 20rem) [col-end]) [center-end]
        minmax(6rem, 1fr) [full-end];
    position: relative;

    @include respond(phone) {
        grid-template-columns:
        [full-start] minmax(2rem, 1fr)
        [center-start] repeat(8, [col-start] minmax(min-content, 20rem) [col-end]) [center-end]
        minmax(2rem, 1fr) [full-end];
    }
}

.container--landing {
    // grid-template-rows: 100vh minmax(100vh, auto) minmax(100vh, auto) 70rem minmax(100vh, auto) 50rem;
    grid-template-rows: 100vh minmax(100vh, auto) 70rem minmax(100vh, auto) 50rem;
    
    @include respond(tab-port) { // width < 900?
        grid-template-rows: 100vh 90vh minmax(100vh, auto) 70rem minmax(100vh, auto) minmax(45rem, auto);
    }
    
    @include respond(phone) { // width < 600?
        grid-template-rows: 100vh 90vh minmax(100vh, auto) min-content minmax(100vh, auto) minmax(45rem, auto);
    }
}

.container--all-products {
    grid-template-rows: 1fr 50rem;
    background-color: $color-grey-light;
    
    @include respond(tab-port) { // width < 900?
        grid-template-rows: 1fr minmax(45rem, auto);
    }
}

.container--shopping-bag {
    grid-template-rows: minmax(100vh, min-content) 50rem;
    @include respond(tab-port) { // width < 900?
        grid-template-rows: minmax(100vh, min-content) minmax(45rem, auto);
    }
}
