.menu {
    display: grid;
    grid-template-columns: min-content;
    grid-template-rows: min-content;

    justify-items: center;
    align-items: center;

    &__checkbox {
        display: none;
    }

    &__button {
        grid-row: 1 / 2;
        grid-column: 1 / 2;

        display: grid;
        justify-content: center;
        align-content: center;

        height: 5rem;
        width: 5rem;
        z-index: 20;
        cursor: pointer;
    }

    &__icon {
        position: relative;
        transform: translateY(-1.2rem);

        &,
        &::after {
            border: none;
            border-radius: 0;
            height: 3px;
            width: 2.9rem;
            background-color: #000;
            display: inline-block;
        }

        &::after {
            content: '';
            position: absolute;
            top: 1.2rem;
            left: 0;
        }
    }

    &__background {
        position: absolute;
        top: 0;
        right: 0;

        width: 50vw;
        height: 100vh;
        z-index: -1;
        opacity: 0;
        visibility: hidden;
        background-color: #000;

        padding: 8rem 13rem;
        
        @include respond(tab-land) {
            padding: 8rem 10rem;
        }
        
        @include respond(tab-port) { // width < 900?
            width: 100vw;
            padding: 10rem 13rem;
        }

        @include respond(phone) { // width < 600?
            padding: 10rem 5rem;
        }

        transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }

    &__nav {
        opacity: 0;
        visibility: hidden;
        transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);

        display: grid;
        grid-template-rows: 1fr min-content;
        height: 100%;
    }

    &__list {
        margin-bottom: 3rem;
        list-style: none;
        align-self: center;
    }

    &__item {
        position: relative;
        font-family: $font-display;
        font-size: 4rem;
        transition: all 0.4s;
        color: #fff;
        
        cursor: pointer;

        
        &:not(:last-child) {
            margin-bottom: 4rem;
        }
        & a {
            color: #fff;
            text-decoration: none;
        }
        
        &::after {
            content: "";
            width: 4rem;
            height: 4px;
            background-color: $color-primary;
            
            position: absolute;
            bottom: 0%;
            left: 0%;
            
            transform: scaleX(0);
            transform-origin: center left;
            transition: all .19s;
        }
        
        &:hover {
            &::after {
            transform: scaleX(1)
            }
        }
    }

    //FUNCTIONALITY
    &__checkbox:checked ~ &__background,
    &__checkbox:checked ~ &__background &__nav {
        z-index: 10;
        opacity: 1;
        visibility: visible;
    }

    &__checkbox:checked + &__button &__icon,
    &__checkbox:checked + &__button &__icon::after {
        background-color: #fff;
    }

    &__checkbox:checked + &__button &__icon {
        transform: rotate(45deg);
    }

    &__checkbox:checked + &__button &__icon::after {
        top: 0;
        transform: rotate(90deg);
    }
}
