.products {
    background-color: $color-grey-light;

    grid-column: full-start / full-end;
    padding: 10rem 0;

    display: grid;
    grid-template-columns:
        [full-start] minmax(6rem, 1fr)
        [center-start] repeat(8, [col-start] minmax(min-content, 20rem) [col-end]) [center-end]
        minmax(6rem, 1fr) [full-end];
        grid-template-rows: min-content 1fr min-content;
        row-gap: 5rem;
        
    @include respond(phone) { // width < 600?
        grid-template-columns:
            [full-start] minmax(2rem, 1fr)
            [center-start] repeat(8, [col-start] minmax(min-content, 20rem) [col-end]) [center-end]
            minmax(2rem, 1fr) [full-end];
    }

    // Special styling for page of products
    &--page {
        row-gap: 8rem;
        padding: 0;
        margin-top: 10rem;

        @include respond(phone) {
            row-gap: 5rem;
        }
        
        & h3 {
            justify-self: center;
        }

        .products__cards {
            row-gap: 14rem;

            @include respond(phone) {
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 3rem;
            }
            
            // Target the cards
            .product-card {
                @include respond(phone) {
                    padding: 2rem;
                    row-gap: 2rem;
                    &__title {
                        font-size: 1.8rem;
                    }
                    &__price {
                        font-size: 1.5rem;
                    }
                    &__figure {
                        height: 22rem;
                    }
                }
            }
        }

        .products__bottom {
            margin-top: 6rem;
        }
    }
    
    & h3 {
        grid-row: 1 / 2;
        grid-column: center-start / center-end;
    }
    
    &__cards {
        grid-row: 2 / 3;
        grid-column: center-start / center-end;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(35rem ,1fr));
        grid-gap: 6rem;
        align-items: start;
        justify-items: center;
        
        @include respond(phone) { // width < 600?
            row-gap: 4rem;
        }
    }
    
    &__bottom {
        grid-row: 3 / 4;
        justify-self: center;
        grid-column: center-start / center-end;
        margin-top: 4rem;
    }
}