.social-media-plug {
    display: grid;
    grid-template-columns: repeat(3, min-content);
    column-gap: 3rem;

    & a:link,
    & a:visited {
        font-size: 1.7rem;
        text-decoration: none;
    }

    &--dark > * {
        color: #000;
    }

    &--light > * {
        color: #fff;
    }

    &--primary > * {
        font-family: $font-primary;
    }

    &--display > * {
        font-family: $font-display;
    }
}
