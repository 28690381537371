.modal {
    width: 100%;
    height: 100%;
    z-index: 30;
    position: fixed;
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    &__background {
        width: 100%;
        height: 100%;
        
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(#000, .45);
    }

    &__content {
        position: relative;
        width: 150rem;
        height: 87rem;
        background-color: #000;
        
        @include respond(tab-land) { // width < 1200?
            width: 95%;
            height: 87rem;
        }
        
        @include respond(tab-port) { // width < 900?
            width: 100%;
            height: 100%;
        }
    }

    &__close {
        position: absolute;
        top: 4rem;
        right: 4rem;

        @include respond(phone) { // width < 600?
            top: 3rem;
            right: 3rem;
        }

        background: none;
        outline: none;
        border: none;

        display: grid;
        justify-content: center;
        align-content: center;
        height: 5rem;
        width: 5rem;
        
        z-index: 1;
        cursor: pointer;

        background-color: #000;
        box-shadow: 0 .5rem 2.5rem rgba(#000, .2);

        & span {
            position: relative;
            transform: rotate(45deg);

            &,
            &::after {
                border: none;
                border-radius: 0;
                height: 3px;
                width: 2.9rem;
                background-color: #fff;
                display: inline-block;
            }

            &::after {
                content: '';
                top: 0;
                transform: rotate(90deg);
            }
        }
    }
}
