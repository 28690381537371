.btn {
    font-family: $font-primary;
    padding: 2rem 5rem;
    outline: none;
    border: none;
    font-size: 1.8rem;
    cursor: pointer;
    transition: all .2s ease-in-out;

    &:hover {
        filter: brightness(.8);
    }
    
    &:disabled,
    &[disabled] {
        filter: brightness(.5);
        cursor: not-allowed;
    }


    @include respond(phone) { // width < 600?
        padding: 2rem 4rem;
    }
    
    &--dark {
        background-color: #000;
        color: #fff;
    }
    
    &--animated {
        position: relative;
        display: inline-block;

        &::before {
            content: "";
            z-index: -1;
            width: 100%;
            height: 100%;
            background-color: $color-primary;
            position: absolute;
            top: 0;
            left: 0;

            transform-origin: top left;
            animation: 1.5s ease-in-out 3s 4 moveInOut;
        }

        &:hover {
            filter: none;
        }
    }

    &--outlined {
        border: 2px solid #000;
        background-color: transparent;
        color: #000;
        padding: 1.8rem 4.8rem;
    }
    
    &--outlined-light {
        border: 2px solid #fff;
        background-color: transparent;
        color: #fff;
        padding: 1.8rem 4.8rem;
    }

    &--link,
    &--link:link,
    &--link:visited {
        text-decoration: none;
    }
    
    &--primary {
        background-color: $color-primary;     
        color: #000;
        font-weight: 700;
        &:hover:enabled {
            filter: brightness(.93);
        }
    }
    
    &--small-pr {
        background-color: $color-primary;     
        color: currentColor;
        font-weight: 600;
        padding: 1.5rem 5rem;
        
        box-shadow: 0 .5rem 1rem rgba(#000, .2);

        &:hover:enabled {
            filter: brightness(.95);
        }
    }
}


