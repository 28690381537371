.product-modal {
    width: 100%;
    height: 100%;

    display:grid;
    grid-template-columns: 57% 43%;
    
    @include respond(tab-land) { // width < 1200?
        grid-template-columns: 53% 47%;
    }
    
    @include respond(tab-port) { // width < 900?
        grid-template-columns: 1fr;
        grid-template-rows: 60% 40%;
    }
    
    @include respond(phone) { // width < 600?
        grid-template-rows: 47% 53%;
    }

    &__picture {
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;

        & img {
            max-height: 70rem;
            height: 100%;

            @include respond(tab-port) { // width < 900?
                height: 80%;
            }
        }
    }

    &__details {
        padding: 8.5rem;

        display: flex;
        flex-direction: column;

        justify-content: flex-end;

        color: #fff;

        @include respond(tab-land) { // width < 1200?
            padding: 6rem;
        }

        @include respond(phone) { // width < 600?
            padding: 5rem;
            justify-content: flex-start;

            overflow-y: scroll;
            &::-webkit-scrollbar {
                width: 10px;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 4px;
                background-color: rgba($color-primary, .4);
            }
        }
    }

    &__form-heading {
        font-size: 3rem;
        color: #fff;
        font-weight: 600;
        margin-bottom: 2rem;
    }

    &__form-group-cont {
        div{
            &:not(:last-of-type) {
                margin-bottom: 1.5rem;
            }
        }

    }

    &__btn-group {
        margin-top: 4rem;
        :first-child {
            margin-right: 2rem;
        }
    }

    &__content {
        padding-right: 8rem;
        @include respond(phone) { // width < 600?
            padding:0;
        }
    }

    &__price {
        font-size: 2rem;
        margin-bottom: 1rem;
    }

    &__title {
        font-family: $font-display;
        font-size: 3.5rem;
        margin-bottom: .3rem;
    }
    
    &__brand {
        font-family: $font-display;
        font-size: 2rem;
        color: $color-grey-dark;
        margin-bottom: 1.5rem;
    }

    &__description {
        font-size: 1.8rem;
        margin-bottom: 5rem;
        
        @include respond(phone) { // width < 600?
            margin-bottom: 3.5rem;
        }
    }

    &__actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include respond(phone) { // width < 600?
            flex-direction: column-reverse;
            align-items: start;
            & > * {
                &:last-child {
                    margin-bottom: 2rem;
                }
            }
        }
    }
}