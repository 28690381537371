.success-prompt {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;

    padding: 0 3rem;

    & > * {
        &:first-child {
            width: 32rem;
            @include respond(phone) { // width < 600?
                width: 25rem;
                letter-spacing: -5.5px;
            }
        }

        &:nth-child(2) {
            margin-bottom: 6rem;
            font-size: 2.3rem;
            transform: translateY(-.5rem);
            @include respond(phone) { // width < 600?
                font-size: 2rem;
            }
        }

        &:last-child {
            color: #fff;
            border: 2px solid #fff;
        }
    }
}